import React from 'react'
import Layout from '../components/Layout'
import Seo from "../components/Seo"

const AboutPage = () => (
  <Layout>
    <Seo title="Projects" />
    <h1>Projects Page</h1>
  </Layout>
)

export default AboutPage;
